import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import Postbuilder from 'components/Postbuilder'
import StickyCard from 'components/StickyCard'

export default function SolutionsTemplate({ data }) {
  const page = data.wpPage
  return (
    <Layout
      bodyClassName='solution-template-default single single-solution postid-172 unified-measurement app-data index-data singular-data single-data single-solution-data single-solution-unified-measurement-data'
      page={page}
    >
      <Main page={page} />
    </Layout>
  )
}

function Main({ page }) {
  const { postbuilder } = page
  return (
    <>
      <section className='single single--light single-solution multivariate-grid multivariate-grid--horizontal-clip'>
        <div className='single-solution__container multivariate-grid__container'>
          <div className='single-solution__row multivariate-grid__row'>
            {postbuilder?.postbuilder && (
              <div className='single-solution__column multivariate-grid__column'>
                <Postbuilder content={postbuilder.postbuilder} />
              </div>
            )}
            <div className='single-solution__column multivariate-grid__column'>
              <StickyCard />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...PostbuilderPage
      ...SeoPage
      ...BannerPage
      ...RelatedBlockPage
      ...ContactBlockPage
    }
  }
`
